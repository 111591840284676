import {Box, Button} from "@mui/material";
import {ArrowBack, Home} from "@mui/icons-material";
import React from "react";
import {useNavigate} from "react-router-dom";

const BackButton = ({route}) => {
    const navigate = useNavigate()

    return (
        <Box position={'absolute'} top={30} left={30}>
            <Button size={'large'}
                    variant={'outlined'}
                    color={'secondary'}
                    startIcon={route ? <Home/> : <ArrowBack/>}
                    onClick={() => {route ? navigate(route) : navigate(-1)}}
            >
                {route ? "Home" : "Indietro"}
            </Button>
        </Box>
    )
}

export default BackButton