import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Button, Grow, Stack, Typography} from "@mui/material";
import {customTheme} from "./theme/customTheme";
import {PlayCircle} from "@mui/icons-material";
import SpinWheelPage from "./spin-wheel/SpinWheelPage";
import visual from "../src/assets/presentation-board-ai-smart-report.png"
import FirstPage from "./pages/FirstPage";
import ThankYouPage from "./pages/ThankYouPage";

const Home = () => {
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
        localStorage.setItem('numSpin', '0')

    }, [])

    const handleInit = () => {
        navigate('/first')
    }

    return (
        <Grow in timeout={800}>
        <Stack alignItems={'center'}>
            <Typography variant={'h1'} fontWeight={'600'} textAlign={'center'}>
                AI SMART REPORT
            </Typography>
            {/*<Typography gutterBottom variant={'h6'} fontWeight={'bold'} textAlign={'center'} maxWidth={'600px'}>
                {"Scopri i benefici potenziali dell'Intelligenza Artificiale per le imprese del Made in Italy".toUpperCase()}
            </Typography>*/}
            <Typography style={{fontSize: '1.25rem', fontWeight: 'bold', textAlign: 'center'}}>
                <p>
                    {"Scopri i benefici potenziali dell'Intelligenza Artificiale".toUpperCase()}
                    <br/>
                    {"per le imprese del Made in Italy".toUpperCase()}
                </p>
            </Typography>
            <Grow in timeout={1500}>
                <Button variant={'contained'}
                        color={'secondary'}
                        endIcon={<PlayCircle fontSize={'large'}/>}
                        onClick={handleInit}
                        sx={{//background: '#F3F5F8',
                            color: customTheme.palette.background.default,
                            paddingX: 2,
                            paddingY: 1.5,
                            width: '20%',
                            mt: 6,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                        }}>
                    Inizia ora
                </Button>
            </Grow>
        </Stack>
        </Grow>
    );
}

function App() {

    useEffect(() => {
        localStorage.clear()
        localStorage.setItem('numSpin', '0')

    }, [])

    return (<div>
            <Grow in timeout={1800}>
                <img src={visual} style={{
                    position: 'absolute',
                    bottom: -250,
                    left: -300,
                    maxWidth: '1200px',
                    overflow: 'hidden',
                    filter: 'brightness(0) invert(1) opacity(0.3)'
                }}/>
            </Grow>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path={'/first'} element={<FirstPage/>}/>
                <Route path={'/spin-wheel'} element={<SpinWheelPage/>}/>
                <Route path={'/thank-you-page'} element={<ThankYouPage/>}/>
            </Routes>
        </div>
    );
}

export default App;