import {customTheme} from "../theme/customTheme";
import {useNavigate} from "react-router-dom";
import {
    alpha,
    Avatar,
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogContent,
    Fade,
    Grow, IconButton,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {AutoGraph, AutoMode, Close} from "@mui/icons-material";
import BackButton from "../components/BackButton";
import {Transition} from "../spin-wheel/SpinWheel";
import visual from "../assets/person-preparing-a-startup-presentation-ai-smart-report-1024x768.png"
import spinWheel from "../assets/img.png"
import ReactPlayer from "react-player";
import QRCode from "react-qr-code";

const FirstPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
        localStorage.setItem('numSpin', '0')

    }, [])

    const handleSpinWheel = () => {
        navigate('/spin-wheel')
    }

    const [open, setOpen] = React.useState(false);
    const [closeButton, toggleCloseButton] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleVideoEnd(event) {
        console.log("handleVideoEnd:", event)
        toggleCloseButton(true)
    }

    return (
        <Stack mt={4}>
            <BackButton/>
            <Fade in timeout={1000}>
                <Typography variant={'h5'} textAlign={'left'} maxWidth={820}>
                    <strong style={{
                        color: customTheme.palette.secondary.main,
                        background: customTheme.palette.primary.main,
                        paddingInline: '6px',
                    }}>AI SMART Report</strong> è uno strumento senza costi nato dalla collaborazione tra <strong
                    style={{
                        color: customTheme.palette.secondary.main,
                        background: customTheme.palette.primary.main,
                        paddingInline: '6px',
                    }}>Google</strong> e la <strong style={{
                    color: customTheme.palette.secondary.main,
                    background: customTheme.palette.primary.main,
                    paddingInline: '6px',
                }}>la School of Management del Politecnico di Milano</strong> che aiuta le imprese italiane a orientarsi nel mondo dell’Intelligenza
                    Artificiale, scoprendone le possibili applicazioni e i relativi benefici attraverso un report
                    personalizzato.
                </Typography>
            </Fade>
            <Stack mt={6} width={'100%'} direction={'row'} alignItems={'baseline'} justifyContent={'space-evenly'}>
                <Grow in timeout={1800}>
                    <Stack width={'350px'} alignItems={'center'}>
                        <Typography gutterBottom sx={{fontSize: '1.25rem'}}
                                    textAlign={'center'} fontStyle={'italic'}>
                            Sei un'impresa che opera nel <br/> Made in Italy?
                        </Typography>
                        <Button variant={'contained'}
                                color={'secondary'}
                                fullWidth
                                size={'large'}
                                endIcon={<AutoGraph fontSize={'large'}/>}
                                onClick={handleClickOpen}
                                sx={{//background: '#F3F5F8',
                                    //color: customTheme.palette.primary.main,
                                    color: customTheme.palette.background.default,
                                    padding: 2,
                                    mt: 1,
                                    mr: 2,
                                    fontWeight: 'bold',
                                    fontSize: '1.25rem'
                                }}>
                            Ottieni il tuo report
                        </Button>
                        <img src={visual} width={'235px'} style={{
                            filter: 'brightness(0) invert(1) opacity(0.3)'
                        }}/>
                    </Stack>
                </Grow>
                <Grow in timeout={2200}>
                    <Stack width={'350px'} alignItems={'center'}>
                        <Typography gutterBottom sx={{fontSize: '1.25rem'}}
                                    textAlign={'center'} fontStyle={'italic'}>
                            Vuoi scoprire degli esempi su come l'IA può essere d'aiuto alle imprese?
                        </Typography>
                        <Button variant={'contained'}
                                color={'secondary'}
                                fullWidth
                                size={'large'}
                                endIcon={<AutoMode fontSize={'large'}/>}
                                onClick={handleSpinWheel}
                                sx={{//background: '#F3F5F8',
                                    //color: customTheme.palette.primary.main,
                                    color: customTheme.palette.background.default,
                                    padding: 2,
                                    mt: 1,
                                    ml: 2,
                                    fontWeight: 'bold',
                                    fontSize: '1.25rem'
                                }}>
                            Gira la ruota
                        </Button>
                        <br/>
                        <img src={spinWheel} width={'120px'} style={{
                            filter: 'brightness(0) invert(1) opacity(0.3)'
                        }}/>
                    </Stack>
                </Grow>
            </Stack>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                //keepMounted
                //maxWidth={'lg'}
                fullScreen
                transitionDuration={{enter: 900, exit: 400}}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Stack px={4} direction={'row'} alignItems={'center'} justifyContent={'space-between'}
                       sx={{color: customTheme.palette.primary.main}}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Avatar sx={{width: 120, height: 120,}} src={visual}/>
                        <Typography variant={'h4'} fontWeight={'bold'}>
                            {'Ottieni il tuo report'}
                        </Typography>
                    </Stack>
                    {/*<IconButton
                        color={'secondary'}
                        onClick={handleSpinWheel}
                        sx={{
                            background: customTheme.palette.background.default,
                            //color: customTheme.palette.primary.main,
                            color: customTheme.palette.secondary.main,
                            paddingInline: 2,
                            paddingBlock: 2,
                            fontSize: '4rem',
                        }}>
                        <Close/>
                    </IconButton>*/}
                </Stack>
                <DialogContent>
                    <ReactPlayer playing
                                 controls
                                 config={{
                                     vimeo: {
                                         playerOptions: {
                                             autopip: false,
                                             pip: false,
                                         }
                                     }
                                 }}
                                 onEnded={handleVideoEnd}
                                 width="100%"
                                 height="90%"
                                 url='https://player.vimeo.com/video/796602727'
                    />
                    {<Backdrop open={closeButton} transitionDuration={1000} sx={{background: alpha('#0000', 0.75)}}>
                            <Grow in={closeButton} timeout={1000}>
                                <Stack spacing={2}>
                                    <Stack pt={2} px={3} pb={4}
                                           alignItems={'center'}
                                           spacing={4}
                                    >
                                        <Typography gutterBottom variant={'h4'} fontWeight={'bold'} maxWidth={'400px'} textAlign={'center'}>
                                            {'Scansiona per ottenere il tuo report'}
                                        </Typography>
                                        <Box p={2} style={{background: customTheme.palette.secondary.main}}
                                             borderRadius={'1.5rem'}
                                             borderColor={'red'}
                                            //border={'8px solid ' + alpha(customTheme.palette.primary.main, 1)}
                                             boxShadow={'inset 0px 0px 12px 1px rgb(50 75 109 / 80%)'}
                                        >
                                            <QRCode
                                                title="AISmartReport.it"
                                                value={"https://mlcheckup.leconomiadellintelligenza.it/index.php/517952?lang=it"}
                                                level={'H'}
                                                bgColor={'white'}
                                                fgColor={customTheme.palette.primary.main}
                                                size={220}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grow>
                        </Backdrop>}
                    {/*<Stack spacing={1} alignItems={'center'}>
                        <Grow in timeout={1700}>
                            <Box p={2} style={{background: 'white'}}
                                 borderRadius={'1.5rem'}
                                 //border={'8px solid ' + alpha(customTheme.palette.primary.main, 1)}
                                 boxShadow={'inset 0px 0px 10px 1px rgb(95 100 107 / 30%)'}
                            >

                            </Box>
                        </Grow>
                    </Stack>*/}
                    <IconButton
                        color={'secondary'}
                        onClick={handleClose}
                        sx={{
                            //background: customTheme.palette.primary.main,
                            //color: customTheme.palette.primary.main,
                            color: customTheme.palette.secondary.main,
                            paddingInline: 2,
                            paddingBlock: 2,
                            position: 'absolute',
                            top: 10,
                            right: 10
                        }}>
                        <Close sx={{
                            fontSize: '4rem',
                        }}/>
                    </IconButton>
                </DialogContent>
            </Dialog>
        </Stack>
    );
}

export default FirstPage