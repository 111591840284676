import {createTheme, responsiveFontSizes} from "@mui/material";

export const customTheme = responsiveFontSizes(createTheme(
    {
        typography: {
            fontFamily: 'OpenSans',
        },
        palette: {
            text: {
                primary: '#F3F5F8'
            },
            background:{
              default:'rgba(81, 135, 188, 1)'
            },
            primary: {
                main:'#324A6D',
                contrastText:'#F3F5F8'
            },
            secondary: {
                main: '#F3F5F8'
            }
        }
    }
))
