import {Grow, Slide, Stack, Typography} from "@mui/material";
import SpinWheel from "./SpinWheel";
import React from "react";
import BackButton from "../components/BackButton";

function SpinWheelPage() {
    return (
        <Stack alignItems={'center'}>
            <BackButton/>
            <Slide in timeout={1000}>
                <Typography variant={'h2'} fontWeight={'600'} textAlign={'center'}>
                    {"Spin the Wheel".toUpperCase()}
                </Typography>
            </Slide>
            <Grow in timeout={1500}>
                <Typography gutterBottom variant={'h5'} textAlign={'center'} maxWidth={'800px'}>
                    Seleziona un settore di tuo interesse o gira la ruota
                </Typography>
            </Grow>
            <SpinWheel/>
        </Stack>
    );
}

export default SpinWheelPage