import {Box, Grow, Stack, Typography} from "@mui/material";
import {customTheme} from "../theme/customTheme";
import React from "react";
import QRCode from 'react-qr-code';
import BackButton from "../components/BackButton";

const ThankYouPage = () => {
    return (
        <>
            <BackButton route={'/'}/>
            <Grow in timeout={800}>
                <Stack alignItems={'center'}>
                    <Typography gutterBottom variant={'h2'} textAlign={'center'} maxWidth={'900px'}fontWeight={'600'}>
                        {"Grazie per aver partecipato!".toUpperCase()}
                    </Typography>
                    {/*<br/>
                    <Grow in timeout={1600}>
                <Button variant={'contained'}
                        color={'secondary'}
                        endIcon={<Home fontSize={'large'}/>}
                        onClick={handleHome}
                        sx={{//background: '#F3F5F8',
                            color: customTheme.palette.background.default,
                            paddingX: 2,
                            paddingY: 1.5,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                        }}>
                    Torna alla home
                </Button>
            </Grow>
            <br/>*/}
                    <br/>
                    <br/>
                    <Grow in timeout={1500}>
                        <Typography gutterBottom variant={'h5'} textAlign={'center'} maxWidth={'800px'}>
                            Visita il sito <strong>AISmartReport.it</strong><br/>per approfondire l'argomento con report,
                            contatti e risorse.
                        </Typography>
                    </Grow>
                    <br/>
                    <Grow in timeout={1700}>
                        <Box p={2} style={{background: customTheme.palette.secondary.main}}
                             borderRadius={'1.5rem'}
                             borderColor={'red'}
                            //border={'8px solid ' + alpha(customTheme.palette.primary.main, 1)}
                             boxShadow={'inset 0px 0px 12px 1px rgb(50 75 109 / 80%)'}
                        >
                            <Stack spacing={1}>
                                <QRCode
                                    title="AISmartReport.it"
                                    value={"https://aismartreport.it"}
                                    level={'H'}
                                    bgColor={customTheme.palette.secondary.main}
                                    fgColor={customTheme.palette.primary.main}
                                    //size={100}
                                />
                                <Typography color={customTheme.palette.primary.main} textAlign={'right'} fontWeight={'bold'}>
                                    AISmartReport.it
                                </Typography>
                            </Stack>
                        </Box>
                    </Grow>
                </Stack>
            </Grow>
        </>
    )
}

export default ThankYouPage